.toolbar{
  background-color: $color-white;
  padding: 20px;
  box-shadow: 0 2px 4px #000000;
}

.btn{
  &-success{

    &_custom{
      background-color: $primary__color;
      border-color: $primary__color;
      border-radius: 3px;

      svg{
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      &.btn-outline{
        background-color: transparent;
        color: $primary__color;

        &.btn-back{
          background-color: #ffffff;
          border: 1px solid $color-greyli;
          color: #666;

          &:hover{
            color: $color-black;
          }

          &:focus {
            color: $color-black;
            box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.3%);
          }
        }

        &:hover{
          color: $color-white;
        }

        &:disabled,
        &.disabled{
          background-color: $color-white;
        }

        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(73, 128, 46, 0.5%);
        }

      }

      &:disabled,
      &.disabled{
        background-color: $primary__color;
        border-color: $primary__color;
        cursor: not-allowed !important;
      }

      &:hover,
      &:active{
        background-color: #49802E;
        border-color: #49802E
      }

      &:focus {
        background-color: #49802E;
        border-color: #49802E;
        box-shadow: 0 0 0 0.25rem rgba(73, 128, 46, 0.5%);
      }
      
    }
  }
  
}


//* Stepper *//

.steps{
  &:after{
    content: "";
    width: 50%;
    height: 1px;
    display: block;
    background: #A8A8A8;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    margin-top: -15px;
  }

  .step{
    &-button{
      width: 45px;
      height: 45px;
    }
    &-item{
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &-title{
      color: $primary__color;
      margin-top: 5px;
    }
    &-name{
      font-size: 12px;
      background-color: #EDF5E7;
      border: 1px solid #A4D881;
      border-radius: 3px;
      color: #6AA742;
      position: absolute;
      bottom: 0;
      margin-bottom: -20px;
      padding: 0 8px; 
  
      &_one{
        left: 0;
        transform: translateX(-50%);
        margin-left: 40px;
      }
      &_two{
        right: 0;
        transform: translatex(50%);
        margin-right: 40px;
      }
    }
  }
}


.step{
  &-one{
    .step-button{
      &[aria-expanded="true"]{
        background: $primary__color;
      }
      &[aria-expanded="false"]{
        background-color: $color-white;
        border: 1px solid $primary__color;
        color: $primary__color;
      }
    }
  }
  &-two{
    .step-button{
      &[aria-expanded="true"]{
        background: $primary__color;
      }
      &[aria-expanded="false"]{
        background: $primary__color;
        border: 1px solid $primary__color;
        color: $color-white;
      }
    }
  }
}

.card-body > .row{
  display: flex;
  justify-content: space-between;
}


#card-left{
  width: auto;
}

#card-right{
  width: auto;
}

.crop-list-item{
  text-align: center;

  .form-check{
    background-color: #F9F9F9;
    border-radius: 4px;
    color: #444444;
    min-height: 40px;
    line-height: 40px;
    padding-left: 0;
    margin-bottom: 15px;
  }
}

#progress{
  height: 2px;
}

.progress-bar{
  background-color: $primary__color;
}


.widget-spreadsheet{
  .table{
    & > :not(:first-child){
      border-top:0 ;
    }
    thead{
      background-color: $primary__color;
      font-weight: normal;
      color: $color-white;
      tr{
        th{
          font-weight: normal;
          &:last-child{
            border-left: 0;
            border-right: 0;
          }
        }
      }
    }
    tbody{
      tr{
        &:first-child{
          background-color:transparent;
          border-color: #EEFCE6;
          // td{
          //   &:last-child{
          //     border-left: 0;
          //     border-right: 0;
          //   }
          // }
        }
        
        // td{
        //   &:last-child{
        //     border-left: 0;
        //     border-right: 0;
        //   }
        // }
      }
    }

  }

  .jexcel{
    border-top: 1px solid transparent;
    border-left: 0;
    border-right: 0;
    width: 100%;

    & > thead{
      & > tr {
        &:first-child td{
          background-color: $primary__color;
          color: $color-white;
        }
        &:nth-child(2) td{
          background-color: #D9ECCD;
          color: $color-black;
        }
      }
    }
    tbody{
      tr {
        td:first-child{
          background-color: #D9ECCD;
          border-left: 0;
        }
      }
    }
    &_container.with-toolbar .jexcel > thead > tr > td{
      top:0;
    }
    &_content{
      padding-right:0;
    }

    }

  .jexcel_contextmenu{
    margin-top: -250px;
    height: 40px;
    width: 70px;
    background: #ffff;
    position: absolute;
    z-index: 2;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    hr{display:none !important;}
    div:nth-child(-n+6) {display:none !important;}
    div:nth-child(8) {display:none !important;}
    div:nth-child(9) {display:none !important;}
    a:hover{
      background: #E9E9E9;
    }

    div{
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        display: none;
      }
    }

    // jexcel_contextmenu jcontextmenu jcontextmenu-focus
    &.jcontextmenu {
      display:none;
      &.jcontextmenu-focus {
        display: block;
      }
    }
  }
  .jexcel_corner{
    display: none !important;
  }

  .card-footer{
    background-color: $color-white;

    .btn-success_custom{
      text-transform: uppercase;
      padding-left:50px;
      padding-right: 50px;
      &:disabled{
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

}

.overflow-x-scroll{
  overflow-x: scroll;
}




.paste-data-here{
  display: flex;
  flex-direction: column;
  color: #AEAEAE;
}

.form-select-date{
  width: auto;
  background-image: url("calendar01.svg");
  background-size: 16px;

  &:focus{
    box-shadow: 0 0 0 0.25rem rgba(73, 128, 46, 0.5%);
    border-color: #49802E; 
  }
}

.form-check{
  &.form-switch{
    padding-left: 0;

    .form-check-label{
      margin: 0 15px;
    }
    
    .form-check-input{
      float:none;
      margin-top: 6px;
      margin-left:0;
    }
  }
  
}

.modal{
  &#TrialModalBulkSelect,
  &#CropModalBulkSelect{

    .modal-dialog{
      max-width: 860px;
    }
    .nosubmit{
      position: relative;

      &:after{
        content: "";
        display: block;
        background-image: url("search.svg");
        background-repeat: no-repeat;
        width: 45px;
        height: 40px;
        background-position: 50% 50%;
        background-size: c;
        position: absolute;
        top: 0;
        right: 0;
      }

      input[name="search"]{
        border: 1px solid #DBDBDB;
        background-color: #F9F9F9;
        padding-right: 0px;
        margin-bottom: 15px;
      }
    }
    .modal-footer{
      display: block;
      border-width: 1px 0 0 0; 
      border-style: solid;
      border-color: #dee2e6;
      padding: 0.75rem;

      .btn{
        width: auto;
        height: auto;
        padding: 8px 25px;
        text-transform: uppercase;
        border-radius: 2px;
      }

    }
  }

  .alert-text{
    color: black;
  }

  .trait-item{
    &.form-check{
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .form-check{
  
        &-input{
          width:25px;
          height: 25px;
          border-radius: 2px;
         
          &:checked{
            opacity: 1;
            margin-top: 2px;
            &[type="checkbox"]{
              background-image: url("check.svg");
            }
          }
        }
        &-label{
          color: #2d2d2d;
          margin-left: 8px;
        }
  
      }
    }
  }
  .sorting-items{
    ul{
      padding-left:0;
    }
    .list-item{
      display: flex;
      align-items: center;
      background-color: $color-white;
      border: 1px solid $color-greyli;
      border-radius: 3px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 10px 0;
      cursor: move;

      &:before{
        content: "";
        display: block;
        background-image:url("draggableIcon.svg");
        width: 13px;
        height: 16px;
        margin-right: 8px;
        background-position: center;
      }
    }
  }

  .form-switch{
    & > .form-check-label{
      margin-left: 0;
    }
  }

  &#orderData{
    .modal-footer{
      padding: 0.75rem;
      border-top: 1px solid #dee2e6;

      .btn-success_custom{
        border-radius: 3px;
        width: auto;
        height: 40px;
        padding: 8px 25px;
        text-transform: uppercase;
      }
    }
  }

}

.form-check-switch{
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.toggler-wrapper{
  display: block;
  width: 42px;
  height: 15px;
  cursor: pointer;
  position: relative;

  .toggler-slider {
    background-color: #E4E4E4;
    position: absolute;
    border-radius: 100px;
    top: 0;
    left: 0;
    width: 42px;
    height: 15px;

    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;

    .toggler-knob{
      width: 22px;
      height: 22px;
      border-radius: 50%;
      left: 0;
      top: -3px;
      background-color: $primary__color;
      position: absolute;

      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;

      -webkit-box-shadow: 0 2px 6px rgba(153, 153, 153, 0.75);
      box-shadow: 0 2px 6px rgba(153, 153, 153, 0.75);
    }
  }

  input[type="checkbox"]{
    display: none;
    &:checked + .toggler-slider .toggler-knob{
      left: calc(100% - 19px - 3px);
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }
  }
}


.measurement-standards{
  background: #f5f5f5;
  border: 1px dotted $color-greyli;
  border-radius: 3px;
  padding: 10px 15px;

  .nosubmit{
    input[name="search"]{
      margin-bottom: 0 !important;
    }
  }
}


.ca{
  visibility: inherit !important;
  z-index: 20 !important;
  opacity: 1 !important;
  border: 1px solid $color-greyli;
  margin-left: 0.02%;
}

.form-check-switch{
  .switch{
    position: relative;
    cursor: pointer;

    input{
      display: none;
      & + .slider,
      & + .slider + .slider{
        display: flex;
        border-radius: 3px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.2px;
        font-weight: 400;

        span{
          display: flex;
          padding: 0 10px;
          height: 25px;
          line-height: 25px;
          position: relative;
          z-index: 2;
        }
      }

      & + .slider{
        background-color: #e9e9e9;
        span{
          color: transparent;

          &:first-child,
          &:last-child{
            overflow: hidden;
            &:before{
              content: "";
              position: absolute;
              top:0;
              left:0;
              right:0;
              bottom:0;
            }
          }
          &:first-child{
            &:before{
              background-color: $primary__color;  
              border-radius: 3px 0 0 3px;
              transform: translateX(0);
              @extend %box_transition;
            }
          }
          &:last-child{
            &:before{
              background-color: $primary__color;
              border-radius: 0 3px 3px 0;
              transform: translateX(-100%);
              @extend %box_transition;
            }
          }
        }
      }
      & + .slider + .slider{
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        span{
          &:first-child{
            color: $color-white;
            @extend %box_transition;
          }
          &:last-child{
            color: #a9a9a9;
          }
        }
      }

      &:checked{
        & + .slider{
          span{
            &:first-child{
              &:before{
                transform: translateX(100%);
                @extend %box_transition;
              }
            }
            &:last-child{
              &:before{
                transform: translateX(0);
                @extend %box_transition;
              }
            }
          }
        }
        & + .slider + .slider{
          span{
            &:first-child{
              color: #a9a9a9;
              @extend %box_transition;
            }
            &:last-child{
              color: $color-white;
              @extend %box_transition;
            }
          }
        }
      }
    } 
  }
}



.list-item{
  display: flex;
  align-items: center;
  &.ca{
    background-color: #ffffff !important;
    border-radius: 2px;
    cursor: move !important;
    border:0;
    z-index: 20;
    margin-left: 0.1%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3%);

    &:before{
      content: "";
      display: block;
      background-image:url("draggableIcon.svg");
      width: 13px;
      height: 16px;
      margin-right: 8px;
      background-position: center;
    }
  }
}

.btn-edit-trial{
  background-color: transparent;
  color: #51A026;
  border-color: #51A026;
  border-radius: 3px;
  width: 125px;
}

.btn-edit-trial:hover{
  background-color: #51A026;
  color:  #ffff;
  border-color: #51A026;
  border-radius: 3px;
}

.steps.step-two:after{
  content: none;
}

.steps.step-two{
  display: flex;
  flex-direction: column;
  gap: 144px;
}

.step-item-edit{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.steps .step-name-edit_one{
  transform: translateY(-860%);
}

.steps .step-name-edit_two{
  transform: translateY(20%);
}

.edit-btns{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-edit-trial_one{
  transform: translateY(-470%);
}

.btn-edit-trial_two{
  transform: translateY(-105%);
}

@media (min-width: 576px){
  .orderData{
    &#CropModalBulkSelect{
      .modal-dialog {
        max-width: 350px;
      }
    }
  }
}
