@import "node_modules/bootstrap/scss/bootstrap.scss";

@import "scss/variable";
@import "scss/mixing";

@import "scss/homepage";

@import "scss/errorpage";
@import "scss/loading";
@import "scss/responsive";
@import "scss/bulkinsert";
@import "scss/eLearning";
@import "scss/admin";
