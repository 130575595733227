// .ftco-section{
//   padding: 7em 0;
// }

.ftco-no-pt {
  padding-top: 0;
}
.ftco-no-pb {
  padding-bottom: 0;
}
//HEADING SECTION
.heading-section {
  font-size: 28px;
  color: $black;
}

.table-wrap {
  @include media-breakpoint-down(xl) {
    overflow-x: scroll;
  }
}
.table-admin {
  min-width: 1000px !important;
  width: 100%;
  background: $white;
  thead {
    border-bottom: 8px solid #e5e5e5;
    &.thead-primary {
      background: #51a026;
    }
    th {
      border: none;
      padding: 20px 30px;
      font-size: 14px;
      color: $white;
    }
  }
  tbody {
    tr {
      margin-bottom: 10px;
      border-bottom: 8px solid #e5e5e5;
      .settingsData-url{
        span{
          max-width: 200px !important;
        }
      }
      td {
        .dataOrder,
        .dataAlign {
          width: 40px;
        }
        .dataParagraph {
          max-width: 480px;
        }
        span {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-width: 150px;
          text-overflow: ellipsis;
        }
        .btn {
          justify-content: center;
        }
      }
    }
    th,
    td {
      border: none;
      // background: $white;
      padding: 20px 30px;
      border-bottom: 3px solid #f8f9fd;
      font-size: 14px;
    }
  }
}

.ftco-section {
  .table-admin {
    button {
      padding: 5px;
    }
  }
  svg {
    margin-right: 0px;
  }
  button {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  a {
    text-decoration: none;
  }
  .data-image-list {
    width: 100px !important;
    height: auto;
    object-fit: cover;
  }
  td {
    padding: 20px 15px !important;
  }
  th {
    padding: 20px 15px !important;
  }
}

.admin-ftform {
  .ant-upload-list-item {
    padding: 0px;
    border: none;
  }
  .ant-upload-list {
    position: relative;
    bottom: 45px;
    margin-left: -20px;
    height: 245px;
    width: 100%;
    .ant-upload-list-picture-card-container {
      width: 100%;
      height: 100%;
    }
  }
  .ant-upload-drag-container {
    p {
      margin-left: 20px;
      position: relative;
      margin-bottom: 0px !important;
    }
  }
  .ant-upload-list-item-thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      padding: 5px;
      height: 245px;
    }
  }
  .ant-upload-list-picture {
    bottom: 50px;
    margin-left: -20px;
    position: relative;
  }
  .heading-section {
    padding: 20px 0px;
  }
  .ant-upload-list-text {
    position: relative;
    bottom: 78px;
    margin: 0px 20px;
    padding: 14px;
    background: white;
  }
  .container {
    padding-left: 20px;
    padding-right: 0px;
  }
  .ant-upload-drag {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dotted rgba(0, 0, 0, 0.4);
    height: 100px;
    margin-left: -20px;
    top: 300px;
    span {
      padding: 0px !important;
    }
  }
  .btn {
    margin-bottom: 20px;
  }
  .ant-upload-list-item-name {
    color: black;
  }
  .ant-breadcrumb {
    ol {
      padding-left: 0px;
    }
    a {
      text-decoration: none;
    }
  }
}

.admin-fileName {
  .ant-upload-list-text {
    display: none;
  }
}

.modal-delete {
  .btn {
    padding: 5px 15px !important;
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
    }
  }
  .modal-header {
    border: none;
  }
  .modal-body {
    height: 204px !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    div {
      button {
        margin: 0px 5px;
      }
      h3 {
        max-width: 90%;
      }
    }
  }
  p {
    color: rgba(0, 0, 0, 0.5);
  }
  .btn {
    &-cancel {
      &_custom {
        color: #ffff;
        background-color: #c1c1c1;
        border-color: #c1c1c1;
        border-radius: 3px;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        &.btn-outline {
          background-color: transparent;
          color: $primary__color;

          &.btn-back {
            background-color: #ffffff;
            border: 1px solid $color-greyli;
            color: #666;

            &:hover {
              color: $color-black;
            }

            &:focus {
              color: $color-black;
              box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.3%);
            }
          }

          &:hover {
            color: $color-white;
          }

          &:disabled,
          &.disabled {
            background-color: $color-white;
          }

          &:focus {
            box-shadow: 0 0 0 0.25rem rgba(73, 128, 46, 0.5%);
          }
        }

        &:disabled,
        &.disabled {
          background-color: #c1c1c1;
          border-color: #c1c1c1;
          cursor: not-allowed !important;
        }

        &:hover,
        &:active {
          background-color: #c1c1c1;
          border-color: #c1c1c1;
        }

        &:focus {
          background-color: #c1c1c1;
          border-color: #c1c1c1;
          box-shadow: 0 0 0 0.25rem rgba(73, 128, 46, 0.5%);
        }
      }
    }
  }
}

.image-upload {
  .ant-upload {
    height: 69% !important;
    margin-left: -20px !important;
    top: 55px !important;
  }
}

.admin-noData{
  font-weight: 500;
}