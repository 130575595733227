.body_loading {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 60vw;
  height: 60vh;
  margin: 20vh auto;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.none {
  display: none;
}

.inner_body_loading {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 44vw;
  height: 42vh;
  margin: 20vh auto;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.loading_body {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 14vh !important;
  margin: 0vh auto !important;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.none {
  display: none;
}


.spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color:#51A026 transparent#51A026 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.lds_ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds_ellipsis div {
  position: absolute;
  top: 33px;
  left: 50px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: red;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds_ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds_ellipsis1 0.6s infinite;
}
.lds_ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds_ellipsis2 0.6s infinite;
}
.lds_ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds_ellipsis2 0.6s infinite;
}
.lds_ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds_ellipsis3 0.6s infinite;
}
@keyframes lds_ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds_ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds_ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
