@media (max-width: 769px) {
  .title-banner{
    p{
      margin-bottom: 15px;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }
}
@media (max-width: 455px) {
.title-banner {
  display: none ;
}
.banner-btn {
  font-size: 14px;
  padding: 10px 15px;
}

.nav-contact button {
  font-size: 12px !important;
}
}