body {
  background-image: url('background.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  row-gap: 36px;
}

.App-main {
  margin: 0 auto;
  position: relative;
  height: 100vh;
}

.nav {
  &bar {
    &-toggler {
      &:focus {
        box-shadow: 0 0 0 0.25rem $primary__color;
      }
    }

    &-default {
      background-color: $color-white;
    }

    @media screen and (max-width: 768px) {
      &-collapse {
        .navbar-nav {
          margin-top: 15px;
        }
        & + ul {
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }
    }
  }

  &-item {
    position: relative;
  }

  &-link {
    font-size: 15px;
    color: #000000;

    &.active {
      color: $primary__color;

      @media screen and (min-width: 992px) {
        &:after {
          content: '';
          background: $primary__color;
          position: absolute;
          width: 100%;
          height: 3px;
          left: 0;
          bottom: 0;
          margin-bottom: -13px;
        }
      }
    }
    &:hover {
      color: $primary__color;
    }
  }
  &-user {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    color: #000000;
    position: relative;

    &:hover {
      color: #000000;
    }

    @media screen and (min-width: 1199px) {
      &:before,
      &:after {
        content: '';
        background-color: #dddddd;
        width: 1px;
        height: 65px;
        position: absolute;
      }

      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }

    &-img {
      display: inline-flex;
      background: green;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      border: 2px solid #ddd;
      overflow: hidden;

      @media screen and (min-width: 1199px) {
        margin-left: 10px;
      }
    }
  }
}

.navbar-brand {
  position: relative;

  @media screen and (min-width: 480px) {
    &.primary {
      width: 62px;
      margin-top: -59px;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  @media screen and (max-width: 885px) {
    &.primary,
    &.secondary {
      display: none;
    }
  }
}

.no-result {
  text-align: center;
  width: 100%;

  @media screen and (min-width: 1199px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon {
    width: 50px;
    height: 50px;
  }
  .title {
    font-size: 14px;
    font-weight: 400;
  }
}

.bulletin {
  width: 360px;
  height: 344px;
  background: #ffffff;
  border-radius: 3px;
  padding: 23px;
  position: relative;
}
.bulletin h3 {
  font-weight: 400;
  font-size: 18px;
}

.pie-chart h3 {
  font-weight: 400;
  font-size: 18px;
}

.pie-chart-div {
  text-align: center;
  canvas {
    @media (max-width: 1199px) {
      display: initial !important;
      width: 183px !important;
      height: 183px !important;
    }
    @media (min-width: 1200px) {
      width: 183px !important;
      height: 183px !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.pie-chart span {
  font-weight: 500;
  font-size: 18px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1199px) {
    margin-top: 10px;
  }
}

.bar-graph {
  width: 1020px;
  height: 344px;
  background: #ffffff;
  border-radius: 3px;
  padding: 20px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bar-graph h3 {
  font-weight: 400;
  font-size: 18px;
}

.bar-graph span {
  font-weight: 400;
  font-size: 14px;
  display: flex;
}
.bar-graph-div {
  width: 400px;
}

.trial-brief {
  display: flex;
  justify-content: flex-start;
  column-gap: 100px;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;

  &.first {
    background: $primary__color;
  }
  &.second {
    background: #666666;
  }
  &.third {
    background: #ffc000;
  }
}

.bar-crop-name {
  display: flex;
}

.tomato {
  padding-left: 17px;
  color: $primary__color;
}

.footer {
  background: #ffffff;
  position: sticky;
  top: 100%;

  &-brand {
    font-size: 18px;
    margin-right: 50px;
  }
  &-navbar {
    ul {
      li {
        a {
          text-decoration: none;
          color: #000000;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
    }
  }
}

.main-title {
  font-size: 18px;
}

.widget {
  &-fav {
    .card-title {
      font-size: 14px;
    }
    &:hover {
      background-color: #51a026;
      .card-title {
        color: #ffffff;
      }
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
  &-news {
    .card-title {
      font-size: 16px;
      color: $black;
    }
    a {
      color: $black;
      text-decoration: none;
      width: 100%;
    }
    .btn-primary {
      display: flex;
      align-items: center;
      background-color: #e5ece0;
      border-color: #e5ece0;
      color: $black;
      font-size: 13px;
      padding: 3px 10px;
      svg {
        margin-left: 5px;
      }
      &:hover {
        background-color: #51a026;
        color: #ffffff;
        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
    .card-image {
      width: 75px;
      height: auto;
      img {
        max-width: 75px;
        min-width: 75px;
        height: auto;
      }
    }
    .meta {
      &-box {
        display: flex;
      }
      &-date,
      &-author {
        font-size: 12px;
        color: #666666;
      }
      &-date {
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  &-flat {
    height: 80px;

    svg {
      float: right;
    }

    &:hover {
      background-color: #51a026;
      h5,
      label {
        color: #ffffff;
      }
      svg {
        path {
          fill: #ffffff;
        }
      }
    }

    h5 {
      font-size: 13px;
    }

    label {
      color: $primary__color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .numb-count {
      background-color: $primary__color;
      color: $color-white;
      padding: 2px 5px;
      text-align: center;
      position: absolute;
      border-radius: 2px;
      font-size: 11px;
      right: -10px;
      top: 12px;
    }

    .text-wrapper {
      display: flex;
      height: 25px;
      overflow: hidden;
    }

    .css-b62m3t-container {
      .css-1s2u09g-control {
        border-color: transparent;
        .css-1okebmr-indicatorSeparator {
          background: transparent;
        }
        .css-14el2xx-placeholder {
          color: $primary__color;
        }
        .css-319lph-ValueContainer {
          padding: 0;
        }
      }
    }
  }
  &-graph {
    .card-body {
      position: relative;
      overflow: hidden;
    }
  }
}

/* ===== Card ====== */

.card {
  background: $color-white;
  border: 0;
  box-shadow: $card-box-shadow;

  .header-title {
    font-size: 16px;
  }
}
.fw-normal {
  font-weight: 500 !important;
}

.sectionTest {
  :global .Select-control {
    height: 30px;
  }
  :global .Select-placeholder {
    line-height: 20px;
  }
  :global .Select-value {
    line-height: 26px !important;
  }

  :global .Select-value-label {
    line-height: 26px;
  }

  :global .Select-input {
    height: 26px;
  }
}
.Select-control {
  height: 30px;

  .Select-placeholder {
    line-height: 26px;
  }

  .Select-value {
    line-height: 26px !important;

    .Select-value-label {
      line-height: 26px;
    }
  }

  .Select-input {
    height: 26px;
  }
}

.modal {
  &-title {
    font-size: 18px;
    color: green;
  }
  .close {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    background: transparent;
    border: 0;
    .sr-only {
      display: none;
    }
  }
  .form-check {
    color: $primary__color;
  }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    opacity: 1;
  }
  .form-check-input:checked {
    background-color: $primary__color;
    border-color: $primary__color;
  }
  &-body {
    height: 400px !important;
    overflow-x: auto;
  }
  &-content {
    border: 0;
    border-radius: 0;
  }
  &-footer {
    padding: 0;
    border: 0;
    .btn {
      margin: 0;
      width: 50%;
      border-radius: 0;
      border: 0;
      height: 50px;
      &-primary {
        background-color: #51a026;
      }
    }
  }
  &-dialog {
    @media (min-width: 576px) {
      max-width: 350px;
    }
  }

  input[name='search'] {
    background: #f3f7f1;
    border: 1px solid #c3ddb5;
    width: 100%;
    border-radius: 3px;
    height: 40px;
    padding: 0 12px;
    margin-bottom: 20px;

    &:focus-visible {
      outline: 0;
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 15;
  color: #0e0d0d;
}

.list-item {
  margin: 0 -16px;
  padding: 6px 15px;
  &:hover {
    background-color: #51a0261f;
  }
}

.search-box {
  bottom: 0;
  background-color: $blue;
  max-height: 40px;
  &::-webkit-input-placeholder {
    color: rgba($white, 0.4);
  }
  .search-input {
    width: 100%;
    height: 100%;
    padding: 0 1em;
    border: 0;
    background-color: transparent;
    opacity: 1;
    color: $white;
  }
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}
.step-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: var(--prm-gray);
  transition: 0.4s;
}
.step-button[aria-expanded='true'] {
  width: 60px;
  height: 60px;
  background-color: var(--prm-color);
  color: #fff;
}
.done {
  background-color: var(--prm-color);
  color: #fff;
}
.step-item {
  z-index: 10;
  text-align: center;
}
#progress {
  -webkit-appearance: none;
  position: absolute;
  width: 95%;
  z-index: 5;
  height: 10px;
  margin-left: 18px;
  margin-bottom: 18px;
}
/* to customize progress bar */
#progress::-webkit-progress-value {
  background-color: var(--prm-color);
  transition: 0.5s ease;
}
#progress::-webkit-progress-bar {
  background-color: var(--prm-gray);
}

ul li ul.dropdown-user {
  display: none;
}
ul li:hover ul.dropdown-user {
  text-align: center;
  top: 50px;
  background: #f2f2f2;
  display: block; /* Display the dropdown */
  min-width: 9rem; /* Set width of the dropdown */
  background: #f2f2f2;
  position: absolute;
  z-index: 999;
  display: flex;
  margin-left: 95px;
  text-decoration: none !important;
  background-color: #ffff;
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 5px !important;
}
ul li ul.dropdown-user li {
  display: block;
}
.dropdown-user {
  a {
    text-decoration: none;
    padding: 5px;
    width: 100%;
    color: black;
  }
  a:hover {
    color: #51a026;
  }
}
.no-line {
  a {
    text-decoration: none;
  }
}

.widget-fav :hover {
  filter: brightness(0) invert(1);
}
