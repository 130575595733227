//COLOR PALETTE

$primary__color:#51A026;
$color-black: #000000;
$color-white: #ffffff;
$color-greyli: #dddddd;
$card-box-shadow: 0 2px 2px rgba(0,0,0,0.10%);

%box_transition {
  -webkit-transition: all 0.3s ease-out;  /* Android 2.1+, Chrome 1-25, iOS 3.2-6.1, Safari 3.2-6  */
  transition: all 0.3s ease-out;  /* Chrome 26, Firefox 16+, iOS 7+, IE 10+, Opera, Safari 6.1+  */
}
