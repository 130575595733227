.error-card {
    max-width: 550px;
    margin: auto;
    position: relative;
    padding-top: 500px;
    padding-bottom: 200px;
    text-align: center;
    .error-img {
        position: absolute;
        left: 0;
        top: 100px;
        img {
            min-width: 670px;
        }
    }
    .error-text-div {
        h2 {
            font-size: 48px;
            color: #131733;
            font-weight: 500;
            margin-bottom: 20px;
        }
        h4 {
            color: #444444;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 0px;
        }
    }
    .thanks-btn-div {
        text-align: center;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        a {
            padding: 17px 65px;
        }
    }
}

@media (max-width: 850px) {
    .error-card {
        max-width: 400px;
        padding-top: 400px;
        padding-bottom: 50px;
        .error-img {
            position: absolute;

            top: 80px;
            img {
                min-width: 500px;
            }
        }
        .error-text-div {
            h2 {
                font-size: 33px;
            }
            h4 {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 450px) {
    .error-card {
        max-width: 270px;
        padding-top: 250px;
        .error-img {
            position: absolute;

            top: 10px;
            img {
                min-width: 350px;
            }
        }
        .error-text-div {
            h2 {
                font-size: 24px;
            }
            h4 {
                font-size: 12px;
            }
        }
    }
}
