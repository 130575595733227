.moreInformation-imgContainer{
    width: 100%;
    height: 290px;
}

.moreInformation-image{
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
}

.btn-browse{
    background-color: #60A33F !important;
    color: #ffff;
}

.btn-browse:hover{
    box-shadow: 1px 1px 5px rgba(0,0,0,0.4);
    color: #ffff;
}

body{
    &.moreInformation-page{
        background-size: 100% 100%;
        background-attachment: fixed;

        .moreInformation-container{
            padding: 40px 0px 80px 0px;
        }

        .navbar{
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .footer{
            position: fixed;
            top: auto;
            bottom: 0;
            width: 100%;
            z-index: 1;
        }

        .card > div > h2{
            color: #60A33F;
        }
        .card{
            max-height: 100% !important;
        }

        .card > button{
            font-size: 14px;
            width: 180px;
            color: #ffff;
        }

        .card > h6{
            font-weight: 400;
        }

        .card > h2{
            font-weight: 600;
        }

        .row{
            padding: 30px 0px;
        }

        .text-wrapper{
            max-width: 80%;
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #444444;
            text-overflow: ellipsis;
            max-height: 40px;
        }

        .no-wrap {
            text-align: justify;
            font-size: 14px;
            color: #444444;
             max-width: 80%;
        }

        .billboard {
            height: 420px;
        }
    }
    .see-more {
        font-weight: 500;
        cursor: pointer;
        max-width: 70px;
    }
}

